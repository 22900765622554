<script>
  import Button from "@smui/button";
  import { getContext } from "svelte";
  import { push } from "svelte-spa-router";

  import knowledgeBanner from "~/assets/images/knowledge_banner.png";
  import manageBanner from "~/assets/images/manage_banner.png";
  import searchBanner from "~/assets/images/search_banner.png";
  import shippingReceiptBanner from "~/assets/images/shipping_receipt_banner.png";
  import uploadBanner from "~/assets/images/upload_banner.png";
  import Edit from "~/components/icons/edit.svelte";
  import Excel from "~/components/icons/excel.svelte";
  import Search from "~/components/icons/search.svelte";
  import User from "~/components/icons/user.svelte";
  import { CONTEXT_KEY_USER } from "~/libs/constants";
  import { activeContent, targetTrackingNumber } from "~/libs/stores";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);
  if (userContext.needsLogin()) {
    push("/");
  }

  let trackingNumber;
  let displayErrorMessage = "none";

  const changeContentUpload = async () => {
    activeContent.set("Upload");
    return;
  };

  const changeContentSearch = async () => {
    activeContent.set("Search");
    return;
  };

  const changeContentManagement = async () => {
    activeContent.set("Management");
    return;
  };

  const changeContentKnowledge = async () => {
    activeContent.set("Knowledge");
    return;
  };

  const changeContentSearchWithTrackingNumber = async () => {
    if (trackingNumber.length >= 12) {
      displayErrorMessage = "none";
      targetTrackingNumber.set(trackingNumber);
      activeContent.set("Search");
    } else {
      displayErrorMessage = "block";
    }
    return;
  };
</script>

<div class="all">
  <div class="searchArea">
    <div class="funcArea">
      <div class="funcTitle">送り状番号検索</div>
      <span class="funcComment">送り状番号を用いて配送情報を検索します。</span>
    </div>
    <div class="inputArea">
      <div>
        <input
          class="inputText"
          type="text"
          bind:value={trackingNumber}
          placeholder="送り状番号を入力"
        />

        <p class="errorMsg" style="display: {displayErrorMessage}">
          ※番号を正しく入力してください。
        </p>
      </div>
      <Button
        style="margin-top: 0; height: 35px; width: 60px;"
        touch
        variant="unelevated"
        on:click={changeContentSearchWithTrackingNumber}
        >検索
      </Button>
    </div>
  </div>
</div>
<div class="flexContainer">
  {#if !userContext.hasSccOperatorRole()}
    <div class="contentArea">
      <label class="contentButton">
        <div class="titleArea">
          <div class="titleLine" />
          <h1 class="title">
            <img
              style="margin: 8px 0 0 10px; width: 154px;"
              src={userContext.hasEcAdminRole()
                ? uploadBanner
                : shippingReceiptBanner}
              alt={userContext.hasEcAdminRole()
                ? "uploadBanner"
                : "shippingReceiptBanner"}
            />
          </h1>
        </div>
        <div class="explanationArea">
          <div class="icons" style="width: 54px; margin: 26px auto 7px;">
            <Excel />
          </div>
          <input type="button" id="button" on:click={changeContentUpload} />
          {#if userContext.hasEcAdminRole()}
            <div class="funcTitle">出荷データ登録</div>
            <span class="funcComment"
              >出荷データの登録や、出荷が確定<br
              />した荷物の登録を行います</span
            >
          {:else}
            <div class="funcTitle">事前荷受け登録</div>
            <span class="funcComment"
              >荷物を一括で荷受け登録<br />
              できるように事前登録を行います</span
            >
          {/if}
        </div>
      </label>
    </div>
  {/if}
  <div class="contentArea">
    <label class="contentButton">
      <div class="titleArea">
        <div class="titleLine" />
        <h1 class="title">
          <img
            style="margin: 10px 0 0 10px; width: 138px;"
            src={searchBanner}
            alt="searchBanner"
          />
        </h1>
      </div>
      <div class="explanationArea">
        <div class="icons" style="width: 80px; margin: 20px auto 0;">
          <Search />
        </div>
        <input type="button" id="button" on:click={changeContentSearch} />
        <div class="funcTitle">その他条件での検索</div>
        <span class="funcComment"
          >送り状番号以外の情報を用いて<br />配送情報を検索します</span
        >
      </div>
    </label>
  </div>
  {#if userContext.hasShippingPartnerAdminRole() || userContext.hasSccOperatorRole()}
    <div class="contentArea">
      <label class="contentButton">
        <div class="titleArea">
          <div class="titleLine" />
          <h1 class="title">
            <img
              style="margin: 10px 0 0 10px; width: 166px;"
              src={knowledgeBanner}
              alt="knowledgeBanner"
            />
          </h1>
        </div>
        <div class="explanationArea">
          <div class="icons" style="width: 72px; margin: 20px auto 7px;">
            <Edit />
          </div>
          <input type="button" id="button" on:click={changeContentKnowledge} />
          <div class="funcTitle">共有配達メモの管理</div>
          <span class="funcComment">
            ドライバーが登録した共有配達メモの<br
            />閲覧・編集などを行います</span
          >
        </div>
      </label>
    </div>
  {/if}
  {#if userContext.hasPermitUserManagementRole()}
    <div class="contentArea">
      <label class="contentButton">
        <div class="titleArea">
          <div class="titleLine" />
          <h1 class="title">
            <img
              style="margin: 10px 0 0 10px; width: 136px;"
              src={manageBanner}
              alt="manageBanner"
            />
          </h1>
        </div>
        <div class="explanationArea">
          <div class="icons" style="width: 80px; margin: 16px auto 4px;">
            <User />
          </div>
          <input type="button" id="button" on:click={changeContentManagement} />
          <div class="funcTitle">ユーザーの管理</div>
          <span class="funcComment"
            >アカウントの追加・削除・<br />
            パスワード変更などを行います</span
          >
        </div>
      </label>
    </div>
  {/if}
</div>

<style>
  .searchArea {
    display: flex;
    margin: 16px 20px 0 50px;
    height: 70px;
    width: 855px;
    padding-top: 16px;
    text-align: center;
    background: rgb(255, 255, 255);
    color: #000000;
    font-size: 16px;
    border-radius: 4px 4px 4px 4px;
    justify-content: center;
  }
  .inputArea {
    margin: 9px 0 0 20px;
    display: flex;
    align-items: top;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }
  .inputText {
    height: 29px;
    width: 250px;
    text-align: center;
  }
  .errorMsg {
    font-size: 12px;
    font-weight: bold;
    margin-top: 4px;
    color: red;
  }
  .funcTitle {
    margin-top: 8px;
    font-weight: 900;
    margin-bottom: 8px;
  }
  .funcComment {
    font-size: 12.6px;
    line-height: 1.3;
  }
  .flexContainer {
    max-width: 855px;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    gap: 15px;
    margin: 15px 0 15px 50px;
  }
  .contentArea {
    width: 270px;
    height: 230px;
    flex-direction: column;
    border: 2px;
    border-color: #064491cb;
    border-style: solid;
    border-radius: 8px 8px 8px 8px;
  }
  .contentButton {
    position: absolute;
    width: 270px;
    height: 230px;
    text-align: center;
    background: rgb(255, 255, 255);
    color: #000000;
    cursor: pointer;
    border-radius: 8px;
  }
  .contentButton:hover,
  .contentButton:focus {
    filter: drop-shadow(1px 1px 1px rgb(170, 170, 170));
  }
  .titleArea {
    height: 40px;
    display: flex;
    background-color: #064491cb;
    padding-bottom: 8px;
    border-radius: 4px 4px 0px 0px;
  }
  .title {
    display: flex;
    font-size: large;
    color: white;
    margin: auto auto auto 10px;
  }
  .explanationArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .icons {
    display: inline-block;
  }
  #button {
    position: absolute;
    opacity: 0;
  }
  @media screen and (max-width: 1171px) {
    /* サイドバーを表示する幅 */
    .searchArea {
      margin: 16px auto 0;
      flex-wrap: wrap;
    }
    .flexContainer {
      width: fit-content;
      margin: 15px auto;
    }
  }
  @media screen and (max-width: 850px) {
    /* 送り状番号検索欄の固定幅 */
    .searchArea {
      width: 100%;
      height: fit-content;
      padding-bottom: 16px;
    }
    .flexContainer {
      justify-content: center;
    }
  }
</style>
