<script>
  import { getContext } from "svelte";

  import knowledgeBanner from "~/assets/images/knowledge_banner.png";
  import manageBanner from "~/assets/images/manage_banner.png";
  import searchBanner from "~/assets/images/search_banner.png";
  import shippingReceiptBanner from "~/assets/images/shipping_receipt_banner.png";
  import uploadBanner from "~/assets/images/upload_banner.png";
  import { CONTEXT_KEY_USER } from "~/libs/constants";
  import { activeContent } from "~/libs/stores";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  const changeContentUpload = async () => {
    activeContent.set("Upload");
    return;
  };

  const changeContentSearch = async () => {
    activeContent.set("Search");
    return;
  };

  const changeContentManagement = async () => {
    activeContent.set("Management");
    return;
  };

  const changeContentKnowledge = async () => {
    activeContent.set("Knowledge");
    return;
  };
</script>

<div class="sidebar">
  {#if !userContext.hasSccOperatorRole()}
    <button class="sibeBarButton" on:click={changeContentUpload}
      ><img
        class="uploadBanner"
        src={userContext.hasEcAdminRole()
          ? uploadBanner
          : shippingReceiptBanner}
        alt={userContext.hasEcAdminRole()
          ? "uploadBanner"
          : "shippingReceiptBanner"}
        style="width: 154px; height: auto; margin-left: 40px; margin-top: 10px;"
      /></button
    >
  {/if}
  <button class="sibeBarButton" on:click={changeContentSearch}>
    <img
      class="searchBanner"
      src={searchBanner}
      alt="searchBanner"
      style="width: 138px; height: auto; margin-left: 40px; margin-top: 10px;"
    />
  </button>
  {#if userContext.hasShippingPartnerAdminRole() || userContext.hasSccOperatorRole()}
    <button class="sibeBarButton" on:click={changeContentKnowledge}>
      <img
        class="knowledgeBanner"
        src={knowledgeBanner}
        alt="knowledgeBanner"
        style="width: 166px; height: auto; margin-left: 42px; margin-top: 6px;"
      />
    </button>
  {/if}
  {#if userContext.hasPermitUserManagementRole()}
    <button class="sibeBarButton" on:click={changeContentManagement}>
      <img
        class="manageBanner"
        src={manageBanner}
        alt="manageBanner"
        style="width: 136px; height: auto; margin-left: 40px; margin-top: 10px;"
      />
    </button>
  {/if}
</div>

<style>
  .sidebar {
    width: 298px;
    height: 100%;
    background-color: #376297;
    position: fixed;
    z-index: 5;
  }
  .uploadBanner {
    margin: 2px 20px;
    height: 53px;
  }
  .searchBanner {
    margin: 2px 10px;
    height: 50px;
  }
  .manageBanner {
    margin: 4px 9px;
    height: 48px;
  }
  .sibeBarButton {
    width: 100%;
    height: 74px;
    text-align: left;
    background-color: #376297;
    border: none;
    border-bottom: 1px solid #ffffffaa;
    cursor: pointer;
  }
</style>
