<script>
  // @ts-nocheck

  import Button from "@smui/button";
  import Checkbox from "@smui/checkbox";
  import Dialog, { Actions, Content, Title } from "@smui/dialog";
  import FormField from "@smui/form-field";
  import Radio from "@smui/radio";
  import Select, { Option } from "@smui/select";
  import Switch from "@smui/switch";
  import imageCompression from "browser-image-compression";
  import { format as formatDate } from "date-fns";
  import { ja as localeJa } from "date-fns/locale";
  import { escape } from "html-escaper";
  import { HTTPError } from "ky";
  import { createEventDispatcher, getContext, onMount, tick } from "svelte";
  import { _ } from "svelte-i18n";

  import backendApi from "~/libs/backendApi";
  import { HandledError } from "~/libs/commonTypes";
  import {
    CHOICES_OF_TIME,
    CONTEXT_KEY_USER,
    DROPPLACE_LIST,
    RETURN_STATUS_LIST,
    RETURN_STATUS_REQUESTING,
    RETURN_STATUS_RETURNING,
    RETURN_STATUS_WAITING,
    ReturnReason,
    STATUS_CREATED,
    STATUS_DELIVERED,
    STATUS_HELD_IN_DEPOT,
    STATUS_IN_TRANSIT,
    STATUS_LIST,
    STATUS_OUT_FOR_DELIVERY,
    TROUBLE_LIST,
  } from "~/libs/constants";
  import depotLocations from "~/libs/depotLocations";
  import desiredDateTime from "~/libs/desiredDateTime";
  import loadingProgress from "~/libs/loadingProgress";
  import { editSearchResult, searchResultUpdateClose } from "~/libs/stores";
  import {
    formatStringDate,
    formatTrackingNumber,
    getCurrentDateTimeOnJst,
  } from "~/libs/utils";
  import LostRegistrationDialog from "~/pages/Search/LostRegistrationDialog.svelte";

  /** @type {import("~/libs/backendApi").SearchedShipment} */
  export let result;
  /** @type {Function} */
  export let updatedResultsReplace;

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  const dispatch = createEventDispatcher();

  /** @type {boolean} */
  let open;
  /** @type {Array<import("~/libs/commonTypes").ExtraEvent>} */
  let extraEvents;
  let displayTracingNumber;
  let displayNumberOfPackages;
  let displayAddress;
  let displayStatus;
  /**
   * 再配達希望日の表示用リスト
   * @type {Array<import("~/libs/commonTypes").DateAndTimeFrame>}
   **/
  let displayDateAndTimeList = [];
  let displayCorrectedReceiverAddress;
  let displayPlace;
  let displayDamaged;
  let displayTroubles;
  let displayReturnStatus;
  let displayReturnReason;
  let displayEcDelivererInternalMessage;
  let displayDelivererInternalMessage;
  let displayShippingPartnerInternalMessage;
  let statusChange = false;
  let dateAndTimeChange = false;
  let correctedReceiverAddressChange = false;
  /** @type {boolean} 実際の配達方法が変更されたか否かを示すフラグ*/
  let actualPackageDropPlaceChange = false;
  /** @type {boolean} 置き配写真が変更されたか否かを示すフラグ */
  let unattendedDeliveryPhotoChange = false;
  /** @type {boolean} 配達票写真が変更されたか否かを示すフラグ */
  let signaturePhotoChange = false;
  let damagedChange = false;
  let troublesStringChange = false;
  let ecDelivererInternalMessageChange = false;
  let delivererInternalMessageChange = false;
  let shippingPartnerInternalMessageChange = false;
  let changedStatus;
  /** @type {string} 変更後の再配達希望日*/
  let changedAdjustedDate;
  let changedStartOfAdjustedTime = "00";
  let changedEndOfAdjustedTime = "24";
  let changedCorrectedReceiverAddress;
  let changedPlace;
  let changedDamaged;
  let changedTroubles = [];
  /** @type {0 | 1 | 2 | 3}*/
  let changedReturnStatus;
  let changedReturnReason;
  let changedEcDelivererInternalMessage;
  let changedDelivererInternalMessage;
  let changedShippingPartnerInternalMessage;
  let statusList = [];
  let returnStatusList = [];
  let returnReasonList = [
    ReturnReason.REQUESTED_RETURN_FROM_EC,
    ReturnReason.REQUESTED_CANCEL_FROM_RECEIVER,
    ReturnReason.ACCEPT_DENIED,
    ReturnReason.ADDRESS_WRONG,
    ReturnReason.ADDRESS_UNKNOWN,
    ReturnReason.REDELIVERY_LIMIT_EXCEEDED,
    ReturnReason.SHIPMENT_PROBLEM,
  ];
  let saveButtonDisabled = true;
  /**
   * 返品理由の選択肢に表示する再配達試行回数
   * @type {number}
   */
  let displayNumberOfDeliveryAttempts;
  /**
   * 配送ステータス登録のバリデーションエラーを表示するかどうかを示すフラグ
   * @type {boolean}
   */
  let displayStatusError = false;

  $: if (
    changedStatus != displayStatus &&
    changedStatus != STATUS_DELIVERED &&
    !returnRegisterChecked
  ) {
    // 配送ステータスが変更された場合
    // かつ 変更先のステータスが「配達完了」以外の場合
    // かつ 「返品ステータスを登録する」がチェックされていない場合
    if (Number.isInteger(selectCenter)) {
      // 配送センターが選択されていない場合、エラーメッセージを表示する
      displayStatusError = false;
    } else {
      displayStatusError = true;
    }
  } else {
    displayStatusError = false;
  }

  /**
   * 返品ステータス・理由登録のバリデーションエラーを表示するかどうかを示すフラグ
   * @type {boolean}
   */
  let displayReturnStatusError = false;
  $: if (returnRegisterChecked) {
    // 「返品ステータス登録を行う」にチェックが入っている場合
    if (
      changedReturnStatus === RETURN_STATUS_WAITING ||
      changedReturnStatus === RETURN_STATUS_RETURNING
    ) {
      // 返品ステータスが「返品待ち」もしくは「返品中」の場合
      if (
        returnReasonList.includes(changedReturnReason) &&
        Number.isInteger(selectCenter)
      ) {
        // 返品理由と配送センターが選択されていない場合、エラーメッセージを表示する
        displayReturnStatusError = false;
      } else {
        displayReturnStatusError = true;
      }
    } else {
      // 返品ステータスが「返品要求」もしくは「返品完了」の場合
      if (returnReasonList.includes(changedReturnReason)) {
        // 返品理由が選択されていない場合、エラーメッセージを表示する
        displayReturnStatusError = false;
      } else {
        displayReturnStatusError = true;
      }
    }
  } else {
    // 「返品ステータス登録を行う」にチェックが入っていない場合はエラーメッセージを表示しない
    displayReturnStatusError = false;
  }

  /**
   * 「返品ステータス登録を行う」にチェックが入っているかどうかを保持する変数
   * @type {boolean}
   */
  let returnRegisterChecked = false;

  /**
   * 「返品ステータス解除を行う」にチェックが入っているかどうかを保持する変数
   * @type {boolean}
   */
  let returnCancelChecked = false;

  /**
   * 「時間帯を指定しない」にチェックが入っているかどうかを保持する変数
   * @type {boolean}
   */
  let noTimeSpecifiedChecked = false;

  /** @type {HTMLInputElement} 置き配写真の入力項目（ファイル選択） */
  let inputUnattendedDeliveryPhoto;

  /** @type {HTMLInputElement} 配達票写真の入力項目（ファイル選択） */
  let inputsignaturePhoto;

  /** @type {boolean} 置き配写真を表示するかどうかを示すフラグ*/
  let displayUnattendedDeliveryPhoto = false;

  /** @type {boolean} 配達票写真を表示するかどうかを示すフラグ*/
  let displaysignaturePhoto = false;

  /** @type {import("svelte").SvelteComponent} 紛失登録ダイアログを表示するコンポーネント */
  let lostRegistrationDialogComponent;

  $: if (
    // いずれかに変更がある場合に変更ボタンを有効化
    statusChange ||
    dateAndTimeChange ||
    correctedReceiverAddressChange ||
    actualPackageDropPlaceChange ||
    unattendedDeliveryPhotoChange ||
    signaturePhotoChange ||
    damagedChange ||
    troublesStringChange ||
    ecDelivererInternalMessageChange ||
    delivererInternalMessageChange ||
    shippingPartnerInternalMessageChange
  ) {
    saveButtonDisabled = false;
    if (displayStatusError || displayReturnStatusError) {
      // 未入力の項目がある場合は保存ボタンを無効化
      saveButtonDisabled = true;
    }
    if (
      dateAndTimeChange &&
      (!changedAdjustedDate ||
        ((!changedStartOfAdjustedTime || !changedEndOfAdjustedTime) &&
          !noTimeSpecifiedChecked))
    ) {
      // 再配達希望日時が未入力の場合は保存ボタンを無効化
      saveButtonDisabled = true;
    }
  } else {
    saveButtonDisabled = true;
  }

  /** @type {import("~/libs/commonTypes").DetailedShipment} */
  let shipment;

  /** @type {string} 輸送中日時 */
  let inTransitAt;

  /** @type {string} 保管中日時 */
  let heldInDepotAt;

  /** @type {string} 持出日時 */
  let outForDeliveryAt;

  /** @type {string} 配達完了日時 */
  let deliveredAt;

  /** @type {Date} 本日の日付 */
  const today = getCurrentDateTimeOnJst();

  /**
   * @typedef {import("~/libs/commonTypes").DepotLocation & {centers: Array<{distance: string, isNeary: boolean}>}} ExtraDepotLocation
   */
  /** @type {Array<ExtraDepotLocation>} */
  let locationList = [];
  let selectCenter;

  onMount(loadingProgress.wrapAsync(openDialog));

  async function openDialog() {
    /** @type {import("~/libs/commonTypes").DetailedShipment} */
    shipment = await backendApi.getDetailedShipment(
      result.trackingNumber,
      true,
    );

    changedReturnStatus =
      shipment.returnStatus ??
      (userContext.hasShippingPartnerAdminRole()
        ? RETURN_STATUS_WAITING
        : RETURN_STATUS_REQUESTING);
    changedReturnReason = shipment.returnReason;

    displayNumberOfPackages = shipment.numberOfPackages;
    displayTracingNumber = formatTrackingNumber(shipment.trackingNumber);
    displayAddress = shipment.receiverAddress1 + shipment.receiverAddress2;
    changedStatus = displayStatus = shipment.status;

    // 現在登録されている配達希望日時、再配達希望日時をもとに初期値を設定
    displayDateAndTimeList = desiredDateTime.resolve(shipment);
    if (displayDateAndTimeList.length == 1) {
      const date = displayDateAndTimeList[0].date;
      const timeFrame = displayDateAndTimeList[0].timeFrame;
      // 日付の初期値設定
      if (date) {
        if (new Date(date) >= today) {
          // 現在登録されている再配達希望日時が今日以降の場合はデフォルト値として設定
          changedAdjustedDate = date;
        }
      }
      // 時間の初期値設定
      if (timeFrame) {
        changedStartOfAdjustedTime = timeFrame.slice(0, 2);
        changedEndOfAdjustedTime = timeFrame.slice(2, 4);
      }
    }

    changedCorrectedReceiverAddress = displayCorrectedReceiverAddress =
      shipment.correctedReceiverAddress;
    changedPlace = displayPlace = shipment.actualPackageDropPlace;
    changedDamaged = displayDamaged = shipment.damaged;
    displayReturnStatus = shipment.returnStatus;
    displayReturnReason = shipment.returnReason;
    changedEcDelivererInternalMessage = displayEcDelivererInternalMessage =
      shipment.ecDelivererInternalMessage;
    displayNumberOfDeliveryAttempts = shipment.numberOfDeliveryAttempts;
    if (!userContext.hasEcAdminRole()) {
      changedDelivererInternalMessage = displayDelivererInternalMessage =
        shipment.delivererInternalMessage;
    }
    if (userContext.hasShippingPartnerAdminRole()) {
      changedShippingPartnerInternalMessage =
        displayShippingPartnerInternalMessage =
          shipment.shippingPartnerInternalMessage;
    }
    let troublesString = "";
    extraEvents = shipment.extraEvent;
    if (extraEvents) {
      extraEvents.forEach((extraEvent) => {
        let changedTrouble = [];
        troublesString +=
          `<div class="extraEventMessage"><p><strong>` +
          $_(`pages.Search.extraEventTypeLabel.${extraEvent.extraEventType}`) +
          "</strong> - " +
          escape(formatStringDate(extraEvent.time, "yyyy/MM/dd HH:mm")) +
          "</p>";
        troublesString +=
          '<p class="content">' +
          $_(`message.trouble.${extraEvent.extraEventType}`) +
          "</p></div>";
        changedTrouble["time"] = extraEvent.time;
        changedTrouble["troubleKbn"] = extraEvent.extraEventType;
        changedTroubles.push(changedTrouble);
      });
      displayTroubles = troublesString;
    }
    inTransitAt = shipment.inTransitAt;
    heldInDepotAt = shipment.heldInDepotAt;
    outForDeliveryAt = shipment.outForDeliveryAt;
    deliveredAt = shipment.deliveredAt;

    // 作業場所（配送センター）の取得
    locationList = /** @type {Array<ExtraDepotLocation>} */ (
      await depotLocations.get()
    );

    makeStatusList(shipment.status);
    makeReturnStatusList();
    judgeDisplayPhoto();
    open = true;
  }

  function makeStatusList(status) {
    if (userContext.hasContractAdminRole()) {
      statusList = STATUS_LIST;
      if (status !== STATUS_OUT_FOR_DELIVERY) {
        statusList = statusList.filter((item) => {
          return item !== STATUS_OUT_FOR_DELIVERY;
        });
      }
    } else if (userContext.hasShippingPartnerAdminRole()) {
      // FIXME: トランプ管理者の場合に輸送中への変更を可能とする
      switch (status) {
        case STATUS_CREATED:
          changedStatus = STATUS_CREATED;
          statusList.push(STATUS_CREATED);
          statusList.push(STATUS_HELD_IN_DEPOT);
          statusList.push(STATUS_DELIVERED);
          break;
        case STATUS_IN_TRANSIT:
          changedStatus = STATUS_IN_TRANSIT;
          statusList.push(STATUS_IN_TRANSIT);
          statusList.push(STATUS_HELD_IN_DEPOT);
          statusList.push(STATUS_DELIVERED);
          break;
        case STATUS_HELD_IN_DEPOT:
          changedStatus = STATUS_HELD_IN_DEPOT;
          statusList.push(STATUS_HELD_IN_DEPOT);
          statusList.push(STATUS_DELIVERED);
          break;
        case STATUS_OUT_FOR_DELIVERY:
          changedStatus = STATUS_OUT_FOR_DELIVERY;
          statusList.push(STATUS_HELD_IN_DEPOT);
          statusList.push(STATUS_OUT_FOR_DELIVERY);
          statusList.push(STATUS_DELIVERED);
          break;
        case STATUS_DELIVERED:
          changedStatus = STATUS_DELIVERED;
          statusList.push(STATUS_HELD_IN_DEPOT);
          statusList.push(STATUS_DELIVERED);
          break;
      }
    } else if (userContext.hasEcAdminRole()) {
      statusList = null;
    }

    const beginningOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    if (
      !userContext.hasEcAdminRole() &&
      status !== STATUS_CREATED &&
      (inTransitAt
        ? new Date(inTransitAt) < beginningOfMonth
        : heldInDepotAt
          ? new Date(heldInDepotAt) < beginningOfMonth
          : outForDeliveryAt
            ? new Date(outForDeliveryAt) < beginningOfMonth
            : deliveredAt
              ? new Date(deliveredAt) < beginningOfMonth
              : false)
    ) {
      // EC管理者ロール以外、かつ現在のステータスが「出荷待ち」ではない
      // かつ「輸送中日時」「保管中日時」「持出日時」「配達完了日時」の内、値が存在する最も早い段階の日時が先月以前の場合
      statusList = statusList.filter((status) => status !== STATUS_CREATED);
    }
  }

  function makeReturnStatusList() {
    if (
      userContext.hasContractAdminRole() ||
      (userContext.hasShippingPartnerAdminRole() &&
        userContext.hasTrumpAdminRole())
    ) {
      returnStatusList = RETURN_STATUS_LIST;
    } else if (
      userContext.hasShippingPartnerAdminRole() &&
      shipment.returnStatus == RETURN_STATUS_REQUESTING
    ) {
      returnStatusList = [RETURN_STATUS_REQUESTING, RETURN_STATUS_WAITING];
    }
  }

  function interfaceDateFormat(date) {
    let year = date.getFullYear().toString();
    let month = ("0" + (1 + date.getMonth()).toString()).slice(-2);
    let day = ("0" + date.getDate().toString()).slice(-2);
    let hour = ("0" + date.getHours().toString()).slice(-2);
    let minute = ("0" + date.getMinutes().toString()).slice(-2);

    let formatStr = "YYYY-MM-DD hh:mm:00";
    formatStr = formatStr.replace(/YYYY/g, year);
    formatStr = formatStr.replace(/MM/g, month);
    formatStr = formatStr.replace(/DD/g, day);
    formatStr = formatStr.replace(/hh/g, hour);
    formatStr = formatStr.replace(/mm/g, minute);

    return formatStr;
  }

  const closeHandler = async (event) => {
    /** @type {import("~/libs/backendApi").SearchedShipment} */
    let newResult;
    /** @type {string} */
    let message;
    switch (event.detail.action) {
      case "save":
        try {
          await execStatusUpdateApi();

          newResult = Object.assign({}, result);
          if (statusChange) {
            if (returnCancelChecked) {
              // 返品ステータスを解除する場合
              newResult.returnStatus = null;
              newResult.returnReason = null;
            } else if (
              returnRegisterChecked ||
              Number.isInteger(result.returnStatus)
            ) {
              // 返品対象として登録する場合もしくは返品ステータスが登録済の場合
              newResult.returnStatus = changedReturnStatus;
              newResult.returnReason = changedReturnReason;
              // 配送ステータスは更新対象外のため、セレクトボックスで変更していた場合戻す
              newResult.status = shipment.status;
            } else {
              newResult.status = changedStatus;
            }
          }
          if (dateAndTimeChange) {
            if (!noTimeSpecifiedChecked) {
              newResult.redeliveryContext = {
                adjustedRedeliveryDatetime: {
                  date: changedAdjustedDate,
                  timeFrame:
                    changedStartOfAdjustedTime + changedEndOfAdjustedTime,
                },
              };
            } else {
              newResult.redeliveryContext = {
                adjustedRedeliveryDatetime: {
                  date: changedAdjustedDate,
                  timeFrame: null,
                },
              };
            }
            newResult.specifiedPickupDatetime = undefined; // BEでクリアされるので初期化
          }
          if (correctedReceiverAddressChange) {
            newResult.correctedReceiverAddress =
              changedCorrectedReceiverAddress;
          }
          if (shippingPartnerInternalMessageChange) {
            newResult.shippingPartnerInternalMessage =
              changedShippingPartnerInternalMessage;
          }
          message = $_("message.updateComplete");
        } catch (error) {
          if (error instanceof HandledError) {
            message = error.message;
          } else {
            if (error instanceof HTTPError && error.response?.status == 400) {
              console.error(error);
              message = $_("errors.updateFailed.message");
            } else if (
              error instanceof HTTPError &&
              error.response?.status == 401
            ) {
              console.error(error);
              message = $_("errors.updateUnauthorized.message");
            } else if (
              error instanceof HTTPError &&
              error.response?.status == 403
            ) {
              console.error(error);
              message = $_("errors.updateForbidden.message");
            } else {
              console.error(error);
              message = $_("errors.updateDefaultMessage.message");
            }
          }
          newResult = null;
        } finally {
          dispatch("message", { text: message });
        }
        break;
      case "cancel":
        break;
    }
    editSearchResult.set(newResult);
    searchResultUpdateClose.set(true);
  };

  const execStatusUpdateApi = loadingProgress.wrapAsync(async () => {
    /** @type {import("~/libs/backendApi").UpdateShipmentEvent} */
    const updateShipmentEvent = {
      trackingNumber: shipment.trackingNumber,
      status: statusChange ? changedStatus : shipment.status,
      version: shipment.version,
    };
    /** @type {Array<"photo" | "returnStatus" | "returnReason">} 初期値対象リスト */
    const initializeFields = [];
    if (
      userContext.hasContractAdminRole() &&
      updateShipmentEvent.status === STATUS_DELIVERED
    ) {
      // TODO: コントラクト管理者かつ配送完了にする場合、暫定的に現在のShipmentに入っているdelivererIdを設定（本来はユーザが選べるべき）
      //       （郵便番号から配送拠点を管轄するdeliveryIdが特定できないケースに対応できないが、2024/1/11時点で当面は問題なさそう）
      updateShipmentEvent.delivererId = shipment.delivererId;
    }

    if (dateAndTimeChange) {
      let adjustedRedeliveryDatetime;
      if (!noTimeSpecifiedChecked) {
        adjustedRedeliveryDatetime = {
          date: changedAdjustedDate,
          timeFrame: changedStartOfAdjustedTime + changedEndOfAdjustedTime,
        };
      } else {
        adjustedRedeliveryDatetime = {
          date: changedAdjustedDate,
          timeFrame: null,
        };
      }
      updateShipmentEvent.redeliveryContext = {
        adjustedRedeliveryDatetime: adjustedRedeliveryDatetime,
      };
      if (shipment.status === STATUS_OUT_FOR_DELIVERY && shipment.driverId) {
        // 持出中の場合はドライバーに通知する
        const message = {
          title: $_(
            "pages.Search.pushNotificationMessage.title.adjustedRedeliveryDatetime",
          ),
          body: $_("pages.Search.pushNotificationMessage.body", {
            values: {
              trackingNumber: formatTrackingNumber(shipment.trackingNumber),
            },
          }),
        };
        updateShipmentEvent.pushNotification = {
          title: message.title,
          body: message.body,
          data: {
            message: message,
            trackingNumber: shipment.trackingNumber,
            adjustedRedeliveryDatetime: adjustedRedeliveryDatetime,
          },
        };
      }
    }
    if (correctedReceiverAddressChange) {
      updateShipmentEvent.correctedReceiverAddress =
        changedCorrectedReceiverAddress;
      if (shipment.status === STATUS_OUT_FOR_DELIVERY && shipment.driverId) {
        // 持出中の場合はドライバーに通知する
        if (!updateShipmentEvent.pushNotification) {
          // pushNotificationが未設定の場合は通知メッセージも含め設定
          const message = {
            title: $_(
              "pages.Search.pushNotificationMessage.title.correctedReceiverAddress",
            ),
            body: $_("pages.Search.pushNotificationMessage.body", {
              values: { trackingNumber: shipment.trackingNumber },
            }),
          };
          updateShipmentEvent.pushNotification = {
            title: message.title,
            body: message.body,
            data: {
              message: message,
              trackingNumber: shipment.trackingNumber,
              correctedReceiverAddress: changedCorrectedReceiverAddress,
            },
          };
        } else {
          // pushNotificationが設定済(再配達希望日時も同時に変更あり)の場合は更新データのみ設定
          updateShipmentEvent.pushNotification.data.correctedReceiverAddress =
            changedCorrectedReceiverAddress;
        }
      }
    }
    if (actualPackageDropPlaceChange) {
      updateShipmentEvent.actualPackageDropPlace = changedPlace;
    }
    if (
      unattendedDeliveryPhotoChange &&
      inputUnattendedDeliveryPhoto?.files?.length > 0
    ) {
      let compressedPhoto = await imageCompression.getDataUrlFromFile(
        await imageCompression(inputUnattendedDeliveryPhoto.files[0], {
          maxSizeMB: 2,
          maxWidthOrHeight: 500,
          useWebWorker: true,
        }),
      );
      updateShipmentEvent.unattendedDeliveryPhoto = compressedPhoto.substring(
        compressedPhoto.indexOf("base64,") + 7,
      );
      compressedPhoto = undefined;
    }
    if (signaturePhotoChange && inputsignaturePhoto?.files?.length > 0) {
      let compressedPhoto = await imageCompression.getDataUrlFromFile(
        await imageCompression(inputsignaturePhoto.files[0], {
          maxSizeMB: 2,
          maxWidthOrHeight: 500,
          useWebWorker: true,
        }),
      );
      updateShipmentEvent.signaturePhoto = compressedPhoto.substring(
        compressedPhoto.indexOf("base64,") + 7,
      );
      compressedPhoto = undefined;
    }
    if (damagedChange) {
      updateShipmentEvent.damaged = changedDamaged;
    }
    if (troublesStringChange) {
      updateShipmentEvent.extraEvent = changedTroubles.map(
        (changedTrouble) =>
          /** @type {import("~/libs/commonTypes").ExtraEvent} */
          ({
            time: changedTrouble.time,
            extraEventType: changedTrouble.troubleKbn,
          }),
      );
    }
    if (statusChange) {
      if (returnCancelChecked) {
        // 返品ステータスを解除する場合
        initializeFields.push("returnStatus");
        initializeFields.push("returnReason");
        // 配送ステータスは更新対象外のため、更新前の状態をセット
        updateShipmentEvent.status = shipment.status;
      } else if (
        returnRegisterChecked ||
        Number.isInteger(shipment.returnStatus)
      ) {
        // 返品対象として登録する場合もしくは返品ステータスが登録済の場合
        updateShipmentEvent.returnStatus = changedReturnStatus;
        updateShipmentEvent.returnReason = changedReturnReason;

        if (
          changedReturnStatus !== RETURN_STATUS_REQUESTING &&
          shipment.status === STATUS_OUT_FOR_DELIVERY
        ) {
          // 返品ステータスが「返品要求」以外、かつ配送ステータスが「持出中」の場合は配送ステータスを「保管中」に変更
          updateShipmentEvent.status = STATUS_HELD_IN_DEPOT;
        } else {
          // 返品ステータスが「返品要求」、もしくは配送ステータスが「持出中」以外の場合は配送ステータスに更新前の状態をセット
          updateShipmentEvent.status = shipment.status;
        }

        if (
          changedReturnStatus === RETURN_STATUS_WAITING ||
          changedReturnStatus === RETURN_STATUS_RETURNING
        ) {
          // 返品ステータスが「返品待ち」もしくは「返品中」の場合は配送センターをセット
          updateShipmentEvent.locationId = selectCenter;
        }
      } else if (
        changedStatus === STATUS_CREATED ||
        changedStatus === STATUS_IN_TRANSIT ||
        changedStatus === STATUS_HELD_IN_DEPOT
      ) {
        // 配送ステータスが「出荷待ち」もしくは「輸送中」もしくは「保管中」の場合は配送センターをセット
        updateShipmentEvent.locationId = selectCenter;
      }
    }
    if (ecDelivererInternalMessageChange) {
      updateShipmentEvent.ecDelivererInternalMessage =
        changedEcDelivererInternalMessage;
      if (
        userContext.hasEcAdminRole() &&
        shipment.status === STATUS_OUT_FOR_DELIVERY &&
        shipment.driverId
      ) {
        // EC事業管理者による更新、かつ持出中の場合はドライバーに通知する
        if (!updateShipmentEvent.pushNotification) {
          // pushNotificationが未設定の場合は通知メッセージも含め設定
          const message = {
            title: $_(
              "pages.Search.pushNotificationMessage.title.delivererInternalMessage",
            ),
            body: $_("pages.Search.pushNotificationMessage.body", {
              values: { trackingNumber: shipment.trackingNumber },
            }),
          };
          updateShipmentEvent.pushNotification = {
            title: message.title,
            body: message.body,
            data: {
              message: message,
              trackingNumber: shipment.trackingNumber,
              ecDelivererInternalMessage: changedEcDelivererInternalMessage,
            },
          };
        } else {
          // pushNotificationが設定済(再配達希望日時or訂正住所も同時に変更あり)の場合は更新データのみ設定
          updateShipmentEvent.pushNotification.data.ecDelivererInternalMessage =
            changedEcDelivererInternalMessage;
        }
      }
    }
    if (delivererInternalMessageChange) {
      updateShipmentEvent.delivererInternalMessage =
        changedDelivererInternalMessage;
      if (shipment.status === STATUS_OUT_FOR_DELIVERY && shipment.driverId) {
        // 持出中の場合はドライバーに通知する
        if (!updateShipmentEvent.pushNotification) {
          // pushNotificationが未設定の場合は通知メッセージも含め設定
          const message = {
            title: $_(
              "pages.Search.pushNotificationMessage.title.delivererInternalMessage",
            ),
            body: $_("pages.Search.pushNotificationMessage.body", {
              values: { trackingNumber: shipment.trackingNumber },
            }),
          };
          updateShipmentEvent.pushNotification = {
            title: message.title,
            body: message.body,
            data: {
              message: message,
              trackingNumber: shipment.trackingNumber,
              delivererInternalMessage: changedDelivererInternalMessage,
            },
          };
        } else {
          // pushNotificationが設定済(再配達希望日時or訂正住所orEC通信欄も同時に変更あり)の場合は更新データのみ設定
          updateShipmentEvent.pushNotification.data.delivererInternalMessage =
            changedDelivererInternalMessage;
        }
      }
    }
    if (shippingPartnerInternalMessageChange) {
      updateShipmentEvent.shippingPartnerInternalMessage =
        changedShippingPartnerInternalMessage;
    }
    if (initializeFields.length > 0) {
      updateShipmentEvent.initializeFields = initializeFields;
    }

    return await backendApi.updateShipment(updateShipmentEvent);
  });

  /** 配送／返品ステータスの変更・戻すボタンクリック時処理 */
  async function statusToggle() {
    statusChange = !statusChange;
    if (statusChange) {
      // ステータス変更中に切り替わった時
      // 変更先のステータスが配完以外の場合は「実際の配達方法」を変更不可にする
      changedStatus !== STATUS_DELIVERED
        ? (actualPackageDropPlaceChange = false)
        : "";
    } else {
      // ステータス未編集に切り替わった時
      // 変更先のステータスが配完以外の場合は「実際の配達方法」を変更不可にする
      displayStatus !== STATUS_DELIVERED
        ? (actualPackageDropPlaceChange = false)
        : "";

      // 返品ステータス解除にチェックがついている場合は状態を戻す
      await tick(); // ここで待たないと勝手にtrueに戻される
      returnCancelChecked = false;
    }
  }

  function dateAndTimeToggle() {
    dateAndTimeChange = !dateAndTimeChange;
    if (dateAndTimeChange) {
      // テキストエリアの高さ + 「配送／返品ステータス」欄の高さ
      const yPosition = 32 + document.getElementById("status").clientHeight;
      document
        .getElementById("redeliveryDateTime")
        .closest("#detail-dialog-content")
        ?.scrollTo({ top: yPosition, behavior: "smooth" });
    }
  }

  function correctedReceiverAddressToggle() {
    correctedReceiverAddressChange = !correctedReceiverAddressChange;
  }

  function actualPackageDropPlaceToggle() {
    actualPackageDropPlaceChange = !actualPackageDropPlaceChange;
  }

  function damagedToggle() {
    damagedChange = !damagedChange;
  }

  function troublesStringToggle() {
    troublesStringChange = !troublesStringChange;
    if (!changedTroubles.length) {
      AddTroubleString();
    }
  }

  function communicationToggleForEc() {
    ecDelivererInternalMessageChange = !ecDelivererInternalMessageChange;
  }

  function communicationToggleForDelivery() {
    delivererInternalMessageChange = !delivererInternalMessageChange;
  }

  function shippingPartnerInternalMessageToggle() {
    shippingPartnerInternalMessageChange =
      !shippingPartnerInternalMessageChange;
  }

  const AddTroubleString = async () => {
    let newTrouble = {};
    newTrouble["time"] = interfaceDateFormat(new Date());
    newTrouble["troubleKbn"] = 0;
    changedTroubles.push(newTrouble);
    changedTroubles = changedTroubles;
  };

  /**
   * 置き配写真と配達票写真の出し分けを行う
   */
  function judgeDisplayPhoto() {
    if (shipment?.signatureRequired) {
      // 配達証明の種別が＜受領印もしくは署名＞の場合
      displaysignaturePhoto = true;
    } else {
      // 配達証明の種別が＜手渡し：なし、置き配：写真＞の場合
      if (shipment?.unattendedDeliveryPhotoUploaded) {
        // 置き配写真が登録されている場合
        displayUnattendedDeliveryPhoto = true;
      } else if (shipment?.signaturePhotoUploaded) {
        // 配達票写真が登録されている場合
        displaysignaturePhoto = true;
      } else {
        // 置き配写真も配達票写真も登録されていない場合
        displayUnattendedDeliveryPhoto = true;
      }
    }
  }

  /**
   * 紛失登録・解除するダイアログを開く。
   */
  function openLostRegistrationDialog() {
    lostRegistrationDialogComponent.dialogOpen();
  }

  /**
   * 紛失登録・解除されたときの処理。
   * @param {boolean} isLost 紛失登録の場合にtrue、解除の場合にfalse
   */
  async function confirmLostRegistrationDialog(isLost) {
    // 編集ダイアログの情報を再読み込み
    openDialog();
    // 一覧表示に反映
    result.lost = isLost;
    updatedResultsReplace(result);
  }

  /**
   * 再配達希望日時を見やすい形にフォーマットする
   * @param {import("~/libs/commonTypes").DesiredDateAndTime} dateAndTimeFrame
   * @returns {string}
   */
  function formatDesiredDateAndTime(dateAndTimeFrame) {
    if (dateAndTimeFrame) {
      // 日付部分のフォーマット
      let formattedDate = "日付指定なし";
      if (dateAndTimeFrame.date) {
        formattedDate = formatStringDate(
          dateAndTimeFrame.date,
          "yyyy/MM/dd(E)",
          {
            locale: localeJa,
          },
        );
      }
      // 時間部分のフォーマット
      let formattedTime = "時間指定なし";
      if (/^\d{4}$/.test(dateAndTimeFrame.timeFrame)) {
        if (
          dateAndTimeFrame.timeFrame === desiredDateTime.UNRECEIVABLE_TIME_SLOT
        ) {
          formattedTime = "受け取り不可";
        } else {
          const timeRangeBegin = `${dateAndTimeFrame.timeFrame.substring(0, 2).replace(/^0/, "")}`;
          const timeRangeEnd = `${dateAndTimeFrame.timeFrame.substring(2).replace(/^0/, "")}`;
          formattedTime = `${timeRangeBegin}${timeRangeEnd != "0" ? "～" + timeRangeEnd + "時" : "時～"}`;
        }
      }
      return `${formattedDate} ${formattedTime}`;
    } else {
      return "日時指定なし";
    }
  }

  /**
   * ダイアログスクロール時にSelectのメニューを非表示にする
   */
  function dialogScrollEvent() {
    for (
      let i = 0;
      i <
      document.getElementsByClassName("mdc-select mdc-select--activated")
        .length;
      i++
    ) {
      let activeSelect = document.getElementsByClassName(
        "mdc-select mdc-select--activated",
      )[i];
      let activeMenu = activeSelect.getElementsByClassName(
        "mdc-select__menu mdc-menu-surface--open",
      )[0];

      activeMenu.classList.remove(
        "mdc-menu-surface--open",
        "mdc-menu-surface--is-open-below",
      );
      activeSelect.classList.remove(
        "mdc-select--activated",
        "mdc-select--focused",
      );
    }
  }
</script>

<div class="dialog">
  <Dialog
    bind:open
    aria-labelledby="detail-dialog-title"
    aria-describedby="detail-dialog-content"
    on:SMUIDialog:closed={closeHandler}
    style="margin-top: 30px; max-height: 90%;"
  >
    <Title id="detail-dialog-title">
      配送情報の編集
      {#if (userContext.hasContractAdminRole() || userContext.hasShippingPartnerAdminRole()) && shipment?.returnStatus != 3 && shipment?.status != 4}
        <!-- IF ログインユーザーがコントラクト管理者か宅配パートナー管理者 かつ 返品完了になっていない かつ 配達完了になっていない場合のみ表示 -->
        {#if shipment?.lost}
          <!-- IF 紛失登録中の場合 -->
          <Button style="width: 130px;" on:click={openLostRegistrationDialog}
            >紛失登録を解除</Button
          >
        {:else}
          <!-- IF 紛失登録中でない場合 -->
          <Button on:click={openLostRegistrationDialog}>紛失登録</Button>
        {/if}
      {/if}
    </Title>

    <Content id="detail-dialog-content" on:scroll={dialogScrollEvent}>
      <div class="readOnlyItemArea">
        <div class="item readOnly">
          <div class="itemTh">送り状番号</div>
          <div class="itemTd">
            <div class="itemTdLeft">
              {displayTracingNumber}{#if displayNumberOfPackages > 1}（{displayNumberOfPackages}個）{/if}
            </div>
          </div>
        </div>
        <div class="item readOnly">
          <div class="itemTh">荷受人の住所</div>
          <div class="itemTd">
            <div class="itemTdLeft">
              {displayAddress}
            </div>
          </div>
        </div>
      </div>
      <div class="editableItemArea">
        <p>以下の項目のみ編集が可能です。</p>
        {#if !userContext.hasEcAdminRole()}
          {#if statusList}
            <div class="item" id="status">
              <div class="itemTh">配送／返品ステータス</div>
              <div class="itemTd">
                <div class="itemTdLeft column">
                  {#if statusChange === false}
                    <!-- IF 表示モードの場合 -->
                    {#if Number.isInteger(shipment?.returnStatus)}
                      <!-- IF 返品ステータスが登録済の場合 -->
                      <div class="showReturnStatusArea">
                        <p class="cancelStatus">
                          {$_(`classes.status.${displayStatus}`)}
                        </p>
                        <p class="validStatus">
                          {#if displayReturnStatus === RETURN_STATUS_REQUESTING && userContext.hasShippingPartnerAdminRole() && !userContext.hasTrumpAdminRole()}
                            {$_(
                              `classes.returnStatusForDeliveryPartner.${displayReturnStatus}`,
                            )}
                            {$_(
                              `classes.returnStatusForDeliveryPartner.${displayReturnStatus}`,
                            )}
                          {:else}
                            {$_(`classes.returnStatus.${displayReturnStatus}`)}
                          {/if}
                        </p>
                      </div>
                      <p>
                        (理由){$_(
                          `classes.returnReason.${displayReturnReason}`,
                        )}
                      </p>
                    {:else}
                      <!-- IF 返品ステータスが未登録の場合 -->
                      <p>{$_(`classes.status.${displayStatus}`)}</p>
                    {/if}
                  {:else}
                    <!-- IF 編集モードの場合 -->
                    <Select
                      variant="outlined"
                      id="statusChange"
                      bind:value={changedStatus}
                      disabled={Number.isInteger(shipment?.returnStatus) ||
                        returnRegisterChecked}
                    >
                      {#each statusList as status}
                        <Option value={status}
                          >{$_(`classes.status.${status}`)}</Option
                        >
                      {/each}
                    </Select>

                    {#if changedStatus != displayStatus && changedStatus != STATUS_DELIVERED && !returnRegisterChecked}
                      <!-- IF 配送ステータスが変更された場合
                            かつ 変更先のステータスが「配達完了」以外の場合
                            かつ 「返品ステータスを登録する」がチェックされていない場合 -->
                      <div class="inputCenterArea">
                        {#if locationList != null}
                          <label class="inputCenterLabel">
                            <select
                              name="inputCenter"
                              class="selectInput"
                              id="inputCenter"
                              bind:value={selectCenter}
                            >
                              <option value="" selected disabled
                                >配送センターを選択してください</option
                              >
                              {#each locationList as { prefecture, centers }}
                                <optgroup label={prefecture}>
                                  {#each centers as { id, name }}
                                    <option value={id}>{name}</option>
                                  {/each}
                                </optgroup>
                              {/each}
                            </select>
                          </label>
                        {:else}
                          <select
                            name="failureCenterFailure"
                            class="selectInput"
                            id="failureCenterFailure"
                            disabled
                          >
                            <option selected>取得失敗</option>
                          </select>
                        {/if}
                      </div>
                    {/if}

                    {#if changedStatus - displayStatus < 0 && !returnRegisterChecked}
                      <!-- IF 配送ステータスが現在のステータスより前の工程に変更された場合 かつ 「返品ステータスを登録する」がチェックされていない場合 -->
                      {#if changedStatus === STATUS_CREATED || changedStatus === STATUS_IN_TRANSIT || changedStatus === STATUS_HELD_IN_DEPOT}
                        <!-- IF 変更後のステータスが出荷待ち・輸送中・保管中のいずれかの場合 -->
                        <div class="initializationWarning">
                          <span class="material-icons"> report_problem </span>
                          <div>
                            <p>
                              「{$_(
                                `classes.status.${changedStatus}`,
                              )}」に戻す場合、以下項目がクリアされます。
                            </p>
                            <ul>
                              {#if changedStatus === STATUS_CREATED}
                                <!-- IF ステータスを出荷待ちに戻す場合 -->
                                <li>荷受け（日時／場所）</li>
                              {/if}
                              {#if displayStatus != STATUS_IN_TRANSIT && (changedStatus === STATUS_CREATED || changedStatus === STATUS_IN_TRANSIT)}
                                <!-- IF ステータスを輸送中以外から出荷待ちもしくは輸送中に戻す場合 -->
                                <li>持ち出し（日時／場所）</li>
                                <li>宅配ドライバー名</li>
                              {/if}
                              {#if changedStatus === STATUS_HELD_IN_DEPOT}
                                <!-- IF ステータスを保管中に戻す場合 -->
                                <li>宅配ドライバー名</li>
                              {/if}
                              {#if displayStatus === STATUS_DELIVERED && (changedStatus === STATUS_CREATED || changedStatus === STATUS_IN_TRANSIT || changedStatus === STATUS_HELD_IN_DEPOT)}
                                <!-- IF ステータスを配達完了から保管中以前に戻す場合 -->
                                <li>配達完了（日時／場所）</li>
                                <li>受け渡し方法（実績）</li>
                                <li>宅配ボックス番号</li>
                                <li>宅配ボックス暗証番号</li>
                                <li>置き配写真</li>
                                <li>配達票写真</li>
                              {/if}
                            </ul>
                          </div>
                        </div>
                      {/if}
                    {/if}

                    {#if displayStatusError}
                      <p class="validationError">
                        配送センターを選択してください。
                      </p>
                    {/if}

                    {#if !Number.isInteger(shipment?.returnStatus)}
                      <!-- IF 返品ステータスが未登録の場合 -->
                      <FormField>
                        <Checkbox bind:checked={returnRegisterChecked} />
                        <span slot="label">返品ステータスを登録する</span>
                      </FormField>
                    {/if}

                    {#if (Number.isInteger(shipment?.returnStatus) && !returnCancelChecked) || (!Number.isInteger(shipment?.returnStatus) && returnRegisterChecked)}
                      <!--
                        IF 返品ステータスが登録済かつ「返品ステータスの登録を解除する」をチェックしている、
                          もしくは返品ステータスが未登録かつ「返品ステータスを登録する」をチェックしている場合
                        -->
                      <div class="returnRegisterArea">
                        <p class="title">【返品に関する登録情報】</p>

                        {#if userContext.hasContractAdminRole() || (userContext.hasShippingPartnerAdminRole() && (userContext.hasTrumpAdminRole() || shipment.returnStatus == RETURN_STATUS_REQUESTING))}
                          <!--
                            コントラクト管理者とトランプ管理者は全返品ステータスを選択可
                            宅配パートナー管理者は「返品要求」状態の場合のみ「返品待ち」へ変更可
                            FIXME: 暫定実装でトランプ管理者が返品集荷・引渡ができるようにしているため要修正
                          -->
                          {#each returnStatusList as returnStatus}
                            <FormField>
                              <Radio
                                bind:group={changedReturnStatus}
                                value={returnStatus}
                              />
                              <span slot="label">
                                {#if returnStatus === RETURN_STATUS_REQUESTING && userContext.hasShippingPartnerAdminRole() && !userContext.hasTrumpAdminRole()}
                                  {$_(
                                    `classes.returnStatusForDeliveryPartner.${returnStatus}`,
                                  )}
                                {:else}
                                  {$_(`classes.returnStatus.${returnStatus}`)}
                                {/if}
                              </span>
                            </FormField>
                          {/each}
                        {/if}

                        {#if !(userContext.hasShippingPartnerAdminRole() && !userContext.hasTrumpAdminRole() && shipment.returnStatus == RETURN_STATUS_REQUESTING)}
                          <!-- IF ログインユーザーが宅配パートナー管理者（トランプ管理者を除く）でない かつ 更新前の返品ステータスが「返品要求」でない場合 -->
                          <Select
                            style="width: 100%; margin-top: 15px;"
                            variant="outlined"
                            bind:value={changedReturnReason}
                            required
                            list$dense
                            list$twoLine
                          >
                            <Option value="" style="height: 50px;"
                              >返品理由を選択してください</Option
                            >
                            {#each returnReasonList as returnReason}
                              <Option
                                value={returnReason}
                                style="height: 50px;"
                              >
                                <p class="returnReasonItem">
                                  {$_(`classes.returnReason.${returnReason}`)}
                                  {#if returnReason == ReturnReason.REDELIVERY_LIMIT_EXCEEDED}
                                    <strong
                                      >(現在{displayNumberOfDeliveryAttempts ??
                                        "0"}回)</strong
                                    >
                                  {/if}
                                  <span class="example">
                                    {$_(
                                      `classes.returnReasonExample.${returnReason}`,
                                    )}
                                  </span>
                                </p>
                              </Option>
                            {/each}
                          </Select>
                        {/if}

                        {#if changedReturnStatus === RETURN_STATUS_WAITING || changedReturnStatus === RETURN_STATUS_RETURNING}
                          <!-- IF 変更後の返品ステータスが返品待ち または 返品中の場合 -->
                          <div class="inputCenterArea">
                            {#if locationList != null}
                              <label class="inputCenterLabel">
                                <select
                                  name="inputReturnCenter"
                                  class="selectInput"
                                  id="inputReturnCenter"
                                  bind:value={selectCenter}
                                >
                                  <option value="" selected disabled
                                    >配送センターを選択してください</option
                                  >
                                  {#each locationList as { prefecture, centers }}
                                    <optgroup label={prefecture}>
                                      {#each centers as { id, name }}
                                        <option value={id}>{name}</option>
                                      {/each}
                                    </optgroup>
                                  {/each}
                                </select>
                              </label>
                            {:else}
                              <select
                                name="failureReturnCenter"
                                class="selectInput"
                                id="failureReturnCenter"
                                disabled
                              >
                                <option selected>取得失敗</option>
                              </select>
                            {/if}
                          </div>
                        {/if}

                        {#if displayReturnStatusError}
                          <p class="validationError">
                            未入力の項目があります。
                          </p>
                        {/if}
                      </div>
                    {/if}

                    {#if Number.isInteger(shipment?.returnStatus) && !(userContext.hasShippingPartnerAdminRole() && shipment.returnStatus == RETURN_STATUS_REQUESTING)}
                      <!-- IF 返品ステータスが登録済の場合 -->
                      <hr style="margin-bottom: 0;" />
                      <FormField>
                        <Checkbox bind:checked={returnCancelChecked} />
                        <span slot="label">返品ステータスの登録を解除する</span>
                      </FormField>
                    {/if}
                  {/if}
                </div>
                <div class="itemTdRight">
                  <Button
                    touch
                    variant="unelevated"
                    on:click={statusToggle}
                    disabled={!(
                      userContext.hasContractAdminRole() ||
                      (userContext.hasShippingPartnerAdminRole() &&
                        userContext.hasTrumpAdminRole()) ||
                      changedReturnStatus === RETURN_STATUS_REQUESTING ||
                      changedReturnStatus === RETURN_STATUS_WAITING
                    ) || shipment?.lost}
                    >{statusChange ? "戻す" : "変更"}</Button
                  >
                </div>
              </div>
            </div>
          {/if}

          {#if userContext.hasShippingPartnerAdminRole()}
            <div class="item" id="redeliveryDateTime">
              <div class="itemTh">再配達希望日時</div>
              <div class="itemTd">
                <div class="itemTdLeft">
                  {#if dateAndTimeChange === false}
                    {#if displayDateAndTimeList.length >= 1}
                      <div>
                        {#each displayDateAndTimeList as dateAndTime}
                          <p>
                            {formatDesiredDateAndTime(dateAndTime)}
                          </p>
                        {/each}
                      </div>
                    {:else if result.redeliveryContext?.redeliveryDatetimeSpecMethod == null}
                      <p>日時指定なし</p>
                    {:else}
                      <p>設定依頼中</p>
                    {/if}
                  {:else}
                    <div>
                      <div class="dateAndTimeSelectArea">
                        <label class="selectLabel" for="deliveryDate"
                          >日付</label
                        >
                        <div class="dateSelect">
                          <input
                            id="deliveryDate"
                            type="date"
                            class="inputDesiredDate"
                            min={formatDate(today, "yyyy-MM-dd", {
                              locale: localeJa,
                            })}
                            bind:value={changedAdjustedDate}
                          />
                        </div>
                      </div>
                      <div class="dateAndTimeSelectArea">
                        <label class="selectLabel" for="deliveryTime"
                          >時間</label
                        >
                        <div class="deliveryTimeSelect">
                          <Select
                            id="deliveryTime"
                            variant="outlined"
                            style="width: 140px;"
                            bind:value={changedStartOfAdjustedTime}
                            disabled={noTimeSpecifiedChecked}
                          >
                            {#each CHOICES_OF_TIME as time, index}
                              {#if index !== CHOICES_OF_TIME.length - 1 && (changedEndOfAdjustedTime !== "" ? time < changedEndOfAdjustedTime : true)}
                                <Option value={time} style="height: 30px"
                                  >{Number(time)}時</Option
                                >
                              {/if}
                            {/each}
                          </Select>
                          <span>～</span>
                          <Select
                            variant="outlined"
                            style="width: 140px;"
                            bind:value={changedEndOfAdjustedTime}
                            disabled={noTimeSpecifiedChecked}
                          >
                            {#each CHOICES_OF_TIME as time, index}
                              {#if index !== 0 && (changedStartOfAdjustedTime !== "" ? time > changedStartOfAdjustedTime : true)}
                                <Option value={time} style="height: 30px"
                                  >{Number(time)}時</Option
                                >
                              {/if}
                            {/each}
                          </Select>
                        </div>
                      </div>
                      <div class="desiredTimeCheckbox">
                        <FormField>
                          <Checkbox bind:checked={noTimeSpecifiedChecked} />
                          <span slot="label">時間帯を指定しない</span>
                        </FormField>
                      </div>
                    </div>
                  {/if}
                </div>
                <div class="itemTdRight">
                  <Button
                    touch
                    variant="unelevated"
                    on:click={dateAndTimeToggle}
                    >{dateAndTimeChange ? "戻す" : "変更"}</Button
                  >
                </div>
              </div>
            </div>
          {/if}

          {#if userContext.hasShippingPartnerAdminRole()}
            <div class="item">
              <div class="itemTh">荷受人の住所 (訂正後)</div>
              <div class="itemTd">
                <div class="itemTdLeft">
                  {#if correctedReceiverAddressChange === false}
                    {#if displayCorrectedReceiverAddress}
                      <p>{displayCorrectedReceiverAddress}</p>
                    {:else}
                      <p class="commentBox">未登録</p>
                    {/if}
                  {:else}
                    <textarea
                      id="correctedReceiverAddressChange"
                      wrap="soft"
                      bind:value={changedCorrectedReceiverAddress}
                      style="width: 390px; height: 50px; padding: 4px;"
                      maxlength="20000"
                    />
                  {/if}
                </div>
                <div class="itemTdRight">
                  <Button
                    touch
                    variant="unelevated"
                    on:click={correctedReceiverAddressToggle}
                    >{correctedReceiverAddressChange ? "戻す" : "変更"}</Button
                  >
                </div>
              </div>
            </div>
          {/if}

          {#if !userContext.hasEcAdminRole() && ((shipment?.status === STATUS_DELIVERED && !statusChange) || (changedStatus === STATUS_DELIVERED && statusChange))}
            <div class="item">
              <div class="itemTh">実際の配達方法</div>
              <div class="itemTd">
                <div class="itemTdLeft">
                  {#if actualPackageDropPlaceChange === false}
                    {#if displayPlace != null}
                      <p>{$_(`classes.packageDropPlace.${displayPlace}`)}</p>
                    {:else}
                      <p>未登録</p>
                    {/if}
                  {:else}
                    <Select
                      variant="outlined"
                      id="actualPackageDropPlaceChange"
                      bind:value={changedPlace}
                    >
                      <Option value="" />
                      {#each DROPPLACE_LIST as dropplace}
                        <Option value={dropplace}
                          >{$_(`classes.packageDropPlace.${dropplace}`)}</Option
                        >
                      {/each}
                    </Select>
                  {/if}
                </div>
                <div class="itemTdRight">
                  <Button
                    touch
                    variant="unelevated"
                    on:click={actualPackageDropPlaceToggle}
                    >{actualPackageDropPlaceChange ? "戻す" : "変更"}</Button
                  >
                </div>
              </div>
            </div>
          {/if}

          {#if displayUnattendedDeliveryPhoto}
            <div class="item">
              <div class="itemTh">置き配写真</div>
              <div class="itemTd">
                <div class="itemTdLeft">
                  {#if !unattendedDeliveryPhotoChange}
                    <p>
                      {shipment?.unattendedDeliveryPhotoUrl
                        ? "登録済"
                        : "未登録"}
                    </p>
                  {:else}
                    <div>
                      <input
                        bind:this={inputUnattendedDeliveryPhoto}
                        type="file"
                        accept="image/png, image/jpeg"
                      />
                    </div>
                  {/if}
                </div>
                <div class="itemTdRight">
                  <Button
                    touch
                    variant="unelevated"
                    on:click={() => {
                      unattendedDeliveryPhotoChange =
                        !unattendedDeliveryPhotoChange;
                    }}>{unattendedDeliveryPhotoChange ? "戻す" : "変更"}</Button
                  >
                </div>
              </div>
            </div>
          {/if}

          {#if displaysignaturePhoto}
            <div class="item">
              <div class="itemTh">配達票写真</div>
              <div class="itemTd">
                <div class="itemTdLeft">
                  {#if !signaturePhotoChange}
                    <p>
                      {shipment?.signaturePhotoUploaded ? "登録済" : "未登録"}
                    </p>
                  {:else}
                    <div>
                      <input
                        bind:this={inputsignaturePhoto}
                        type="file"
                        accept="image/png, image/jpeg"
                      />
                    </div>
                  {/if}
                </div>
                <div class="itemTdRight">
                  <Button
                    touch
                    variant="unelevated"
                    on:click={() => {
                      signaturePhotoChange = !signaturePhotoChange;
                    }}>{signaturePhotoChange ? "戻す" : "変更"}</Button
                  >
                </div>
              </div>
            </div>
          {/if}

          <div class="item">
            <div class="itemTh">輸送中の外装汚損の<br />発生有無</div>
            <div class="itemTd">
              <div class="itemTdLeft">
                {#if damagedChange === false}
                  <p>{displayDamaged ? "有り" : "無し"}</p>
                {:else}
                  <div>
                    <Switch
                      bind:checked={changedDamaged}
                      color="secondary"
                      icons={false}
                    />
                    <span>{changedDamaged ? "有り" : "無し"}</span>
                  </div>
                {/if}
              </div>
              <div class="itemTdRight">
                <Button touch variant="unelevated" on:click={damagedToggle}
                  >{damagedChange ? "戻す" : "変更"}</Button
                >
              </div>
            </div>
          </div>

          <div class="item">
            <div class="itemTh">配送中の発生事象</div>
            <div class="itemTd">
              {#if troublesStringChange === false}
                <div class="itemTdLeft">
                  {#if displayTroubles}
                    <div class="extraEventMessages">
                      {@html displayTroubles}
                    </div>
                    <!-- pre-escaped -->
                  {:else}
                    <p>未登録</p>
                  {/if}
                </div>
              {:else}
                <div class="itemTdLeft column">
                  {#each changedTroubles as extraEvent, index}
                    <p style:margin-top={index == 0 ? "0" : "10px"}>
                      {formatStringDate(extraEvent.time, "yyyy/MM/dd HH:mm")}
                    </p>
                    <!-- 配送中の発生事象 -->
                    <div class="troubleLine">
                      <p>区分：</p>
                      <select
                        name="troubleKbn"
                        id="troubleKbn"
                        bind:value={extraEvent.troubleKbn}
                      >
                        {#each TROUBLE_LIST as troubleKbn}
                          <option value={troubleKbn}
                            >{$_(
                              `pages.Search.extraEventTypeLabel.${troubleKbn}`,
                            )}</option
                          >
                        {/each}
                      </select>
                    </div>
                  {/each}
                </div>
              {/if}
              <div class="itemTdRight troubleBtnArea">
                <Button
                  touch
                  variant="unelevated"
                  on:click={troublesStringToggle}
                  >{troublesStringChange ? "戻す" : "変更"}</Button
                >
                {#if troublesStringChange}
                  <button class="troublePlus" on:click={AddTroubleString}
                    >＋</button
                  >
                {/if}
              </div>
            </div>
          </div>
        {/if}

        <!-- 通信欄 (対EC事業者) -->
        <div class="item">
          <div class="itemTh">
            通信欄{#if !userContext.hasEcAdminRole()}<br />(対EC事業者){/if}
          </div>
          <div class="itemTd">
            <div class="itemTdLeft column">
              {#if ecDelivererInternalMessageChange === false}
                {#if displayEcDelivererInternalMessage}
                  <p class="commentBox">
                    {@html escape(changedEcDelivererInternalMessage).replace(
                      /\n/g,
                      "<br />",
                    )}
                  </p>
                {:else}
                  <p class="commentBox">未登録</p>
                {/if}
              {:else}
                <textarea
                  id="communicationChange"
                  wrap="soft"
                  bind:value={changedEcDelivererInternalMessage}
                  style="width: 390px; height: 70px; padding: 4px;"
                  maxlength="20000"
                />
              {/if}
              <p class="note">
                ※お客様へは公開されない、{userContext.hasEcAdminRole()
                  ? "配送事業者"
                  : "EC事業者"}との情報共有スペース
              </p>
            </div>
            <div class="itemTdRight">
              <Button
                touch
                variant="unelevated"
                on:click={communicationToggleForEc}
                >{ecDelivererInternalMessageChange ? "戻す" : "変更"}</Button
              >
            </div>
          </div>
        </div>

        <!-- 通信欄 (配送事業者内) -->
        {#if !userContext.hasEcAdminRole()}
          <div class="item">
            <div class="itemTh">通信欄<br />(配送事業者内)</div>
            <div class="itemTd">
              <div class="itemTdLeft column">
                {#if delivererInternalMessageChange === false}
                  {#if displayDelivererInternalMessage}
                    <p class="commentBox">
                      {@html escape(changedDelivererInternalMessage).replace(
                        /\n/g,
                        "<br />",
                      )}
                    </p>
                  {:else}
                    <p class="commentBox">未登録</p>
                  {/if}
                {:else}
                  <textarea
                    id="communicationChange"
                    wrap="soft"
                    bind:value={changedDelivererInternalMessage}
                    style="width: 390px; height: 140px; padding: 4px;"
                    maxlength="20000"
                  />
                {/if}
                <p class="note">
                  ※お客様とEC事業者へは公開されない、配送事業者内(幹線輸送事業者と宅配事業者)の情報共有スペース
                </p>
              </div>
              <div class="itemTdRight">
                <Button
                  touch
                  variant="unelevated"
                  on:click={communicationToggleForDelivery}
                  >{delivererInternalMessageChange ? "戻す" : "変更"}</Button
                >
              </div>
            </div>
          </div>
        {/if}

        {#if userContext.hasShippingPartnerAdminRole()}
          <div class="item">
            <div class="itemTh">宅配事業者管理者専用<br />メモ欄</div>
            <div class="itemTd">
              <div class="itemTdLeft column">
                {#if shippingPartnerInternalMessageChange === false}
                  {#if displayShippingPartnerInternalMessage}
                    <p class="commentBox">
                      {@html escape(
                        changedShippingPartnerInternalMessage,
                      ).replace(/\n/g, "<br />")}
                    </p>
                  {:else}
                    <p class="commentBox">未登録</p>
                  {/if}
                {:else}
                  <textarea
                    id="shippingPartnerInternalMessageChange"
                    wrap="soft"
                    bind:value={changedShippingPartnerInternalMessage}
                    style="width: 390px; height: 140px; padding: 4px;"
                    maxlength="20000"
                  />
                {/if}
                <p class="note">
                  ※宅配事業者の管理者専用の内部メモ (管理業務の状況整理用)
                </p>
                <p class="note">
                  ※「日付＋状況毎に固定の文言」の形式でご入力いただくと、検索結果一覧でフィルター・ソートを使用して状況の把握がしやすくなります。
                  <span
                    style="display: inline-block; margin-top: 3px; margin-left: 1em;"
                  >
                    例）<span class="highlight">0701管理者確認</span>、<span
                      class="highlight">0702住所不明:待機</span
                    >、<span class="highlight">0709住所不明:返品</span>
                  </span>
                </p>
              </div>
              <div class="itemTdRight">
                <Button
                  touch
                  variant="unelevated"
                  on:click={shippingPartnerInternalMessageToggle}
                  >{shippingPartnerInternalMessageChange
                    ? "戻す"
                    : "変更"}</Button
                >
              </div>
            </div>
          </div>
        {/if}
      </div>
    </Content>

    <Actions>
      <Button action={"cancel"} style="background-color: #D9D9D9; color: #333;"
        >キャンセル</Button
      >
      <Button
        action={"save"}
        touch
        variant="unelevated"
        bind:disabled={saveButtonDisabled}>保存</Button
      >
    </Actions>
  </Dialog>
</div>
<LostRegistrationDialog
  bind:this={lostRegistrationDialogComponent}
  {shipment}
  {confirmLostRegistrationDialog}
/>

<style lang="scss">
  .dialog {
    :global(.mdc-select--outlined .mdc-select__anchor) {
      width: 300px;
      height: 36px;
    }
    :global(.mdc-select .mdc-select__menu) {
      max-height: 180px !important;
    }
    :global(.mdc-button--touch) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .readOnlyItemArea {
    background-color: #fff;
    padding: 8px 24px;
    position: sticky;
    top: 0;
    z-index: 2;
    width: 635px;
  }
  .editableItemArea {
    padding: 8px 24px;
    width: 635px;
  }
  .item {
    display: flex;
    gap: 10px;
    position: relative;
    padding: 6px 0;
    border-bottom: 1px solid #eee;
    width: 635px;

    &.readOnly {
      padding: 1px 0;
      border-bottom: none;
    }
  }

  .itemTh {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    padding: 10px 0;
    min-width: 120px;
    line-height: 1.2em;
    background-color: #b4d0f1cb;
    color: #242424;
    font-size: smaller;
    font-weight: 900;
    text-align: center;
  }
  .item.readOnly .itemTh {
    background-color: #dbdbdb;
  }
  .itemTd {
    display: flex;
    flex-grow: 1;
    align-items: center;
    gap: 10px;
  }

  .column {
    flex-direction: column;
  }

  .itemTdLeft {
    width: 400px;
    flex-grow: 1;
    display: flex;
    color: #333;
    font-size: 13px;

    .showReturnStatusArea {
      display: flex;

      .validStatus {
        font-size: 15px;
        color: red;
      }

      .cancelStatus {
        font-size: 12px;
        text-decoration: line-through;
      }
    }

    .inputCenterArea {
      .inputCenterLabel {
        display: inline-flex;
        align-items: center;
        position: relative;
        width: 100%;
        margin-top: 16px;
        &::after {
          position: absolute;
          right: 19px;
          width: 10px;
          height: 7px;
          background-color: #666;
          clip-path: polygon(0 0, 100% 0, 50% 76%);
          content: "";
          pointer-events: none;
        }
        select {
          appearance: none;
          width: 100%;
          height: 2.8em;
          padding: 0.4em 30px 0.4em 1.2em;
          border: 1px solid #999;
          border-radius: 3px;
          background-color: #fff;
          color: #333333;
          font-size: 1em;
          cursor: pointer;
        }
        select:hover {
          border-color: #333;
        }
      }
      .selectInput {
        height: 40px;
      }
    }

    .returnRegisterArea {
      margin-top: 12px;

      .title {
        font-size: 15px;
        font-weight: 500;
      }

      .returnReasonItem {
        line-height: 1.2;

        .example {
          display: inline-block;
          margin-top: 4px;
          font-size: 12px;
          color: var(--mdc-theme-secondary);
        }
      }

      :global(.mdc-form-field) {
        height: 30px;
      }
    }

    .inputDesiredDate {
      box-sizing: border-box;
      width: 302px;
      height: 38px;
      padding: 0 12px;
      border: 1px solid #999;
      border-radius: 5px;
    }

    .desiredTimeCheckbox {
      margin-left: 24px;
    }

    .extraEventMessages {
      :global(.extraEventMessage .content) {
        width: 390px;
        padding: 4px;
        border: 1px solid #ddd;
        border-radius: 4px;
        line-height: 1.3em;
        font-size: 12px;
      }
      :global(.extraEventMessage:nth-child(n + 2)) {
        margin-top: 8px;
      }
    }
    .commentBox {
      width: 390px;
      padding: 4px;
      font-size: 12px;
      border: 1px solid #ddd;
      border-radius: 4px;
      line-height: 1.2em;
      overflow-wrap: break-word;
    }
    .note {
      margin-top: 6px;
      padding-left: 1em;
      text-indent: -1em;
      line-height: 1.2em;
      font-size: 11px;
      color: #666;

      .highlight {
        background-color: #e6e6e6;
        border-radius: 4px;
        margin-top: 3px;
        padding: 3px;
      }
    }
    .troubleLine {
      display: flex;
    }
    .validationError {
      color: red;
      font-weight: bold;
      margin-top: 8px;
    }
    .initializationWarning {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      margin-top: 16px;
      padding: 14px 10px;
      background-color: #ffe7e7;
      border-radius: 4px;
      line-height: 20px;
      .material-icons {
        color: #c80000;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        margin-top: 6px;
      }
      li:before {
        content: "「";
      }
      li::after {
        content: "」";
      }
    }

    :global(.mdc-select__selected-text),
    :global(.mdc-deprecated-list-item) {
      font-size: 14px;
    }
  }

  .dateAndTimeSelectArea {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 8px 0;

    :global(.mdc-select .mdc-select__menu) {
      max-height: 130px !important;
    }
  }

  .itemTdRight.troubleBtnArea {
    display: flex;
    justify-content: start;
    gap: 12px;
    align-items: flex-start;
    flex-direction: column;

    .troublePlus {
      width: 50px;
      height: 50px;
      margin-left: 6px;
      font-size: 20px;
      border: none;
      border-radius: 50%;
    }
  }

  .dialog {
    :global(.mdc-dialog .mdc-dialog__container) {
      width: 90%;
    }
    :global(.mdc-dialog .mdc-dialog__surface) {
      width: 100%;
      min-width: 300px;
      max-width: 700px;
    }
    :global(.mdc-dialog__title) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 13px;
      padding-bottom: 12px;
    }
    :global(.mdc-dialog__title .mdc-button) {
      background-color: #f90404;
      color: #fff;
      margin-left: auto;
      width: 100px;
    }

    :global(.mdc-dialog__content) {
      padding: 0;
    }
  }
</style>
